
import moment from 'moment';
export default{
    name : 'changelog',
    data(){
        return{

        }
    },

    filters:{
        log_type_abr(min_type){
            return min_type.substr(0,1);
        },

        content_manage(content){
            content =  content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ').replace(/&nbsp;/,' ');

            if(content.length > 20){
                return content.substr(0, 20) + '...';
            }else{
                return content
            }
        },

        regular_format(date){
            return moment(date , 'YYYY-MM-DD HH:mm:ss').format("DD MMM, YYYY");
        },

    }
}
