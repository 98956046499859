import moment from 'moment';
import 'moment-timezone';

export default{
    name : 'viewParticipates',
    components:{
    },
    data(){
        return{
           group_by_partcipants:{},
           attended_partcipants: true,
           not_attended_partcipants : true
        }
    },
    async created(){ 
        this.groupByParticipants();
    },
    methods:{

        async groupByParticipants(){
            let meeting_id = this.$route.params.meeting_id;
            await this.$store.dispatch('getMeetingParticipants',[this.$store.state.api, { "meeting_id": meeting_id}]);
            this.group_by_partcipants= this.$store.state.meeting_participants.reduce(function (r, a) {
                r[a.attendance_status] = r[a.attendance_status] || [];
                r[a.attendance_status].push(a)
                return r;
            },{});   
        }
    },
   filters:{
        time_12hr(date_time,time_zone){
            return moment(date_time).utc().tz(time_zone).format("hh:mm A");
        },

        day_month_date(date_time,time_zone){
            return moment(date_time).utc().tz(time_zone).format("ddd MMM DD, YYYY");
        },
    }
}