import moment from 'moment';
import Topbar from '@/components/topbar/topbar_component.vue';
import Navbar from '@/components/navbar/navbar_component.vue';
import PartcipateView from '@/components/participantView/viewparticipants_component.vue';
import changelogView from '@/components/changelog/changelog_component.vue';
import Loader from '@/components/Loaders/loader_component.vue';
import 'moment-timezone';

export default{
    name : 'viewMinutes',
    components:{
        Topbar,
        Navbar,
        PartcipateView,
        changelogView,
        Loader
    },
    data(){
        return{

        }
    },

    async mounted(){
        let meeting_id = this.$route.params.meeting_id;
        await this.$store.dispatch('getMeetingMinutes',[this.$store.state.api, { "meeting_id": meeting_id}]);
        await this.$store.dispatch('changelogDetails',[this.$store.state.api, { "meeting_id": meeting_id}]);
    },
    methods:{
        async download(){
            let meeting_id = this.$route.params.meeting_id;
            let result = await this.$store.dispatch('getMeetingMinutesDownload',[this.$store.state.api, { "meeting_id": meeting_id,"local_timezone": this.Constants.MEET_TIME_ZONE}]);
            if(result.message.includes('success')){
                window.location.href = this.$store.state.api+'stylesheets/meeting_'+meeting_id+'.docx';
            }
        },
        async view_meeting(){
            let meeting_id = this.$route.params.meeting_id;
            await this.publish_meeting('Draft');
            this.$router.push('/draftminutes/'+meeting_id);
        },
        async publish_meeting(type){ 
            let meeting_obj = {
                "id": this.$route.params.meeting_id,
                "status": type,
                "local_timezone": this.Constants.MEET_TIME_ZONE,
                "meeting_end_time" : moment().format("YYYY-MM-DD HH:mm")
            }
            this.$store.state.loader_popup = true;
            await this.$store.dispatch('CrudMeeting',[this.$store.state.api, meeting_obj]);
            this.$store.state.loader_popup = false;
        },
    },

    filters:{
        time_12hr(date_time,time_zone){
            return moment(date_time).utc().tz(time_zone).format("hh:mm A");
        },

        day_month_date(date_time,time_zone){
            return moment(date_time).utc().tz(time_zone).format("ddd MMM DD, YYYY");
        },

        regular_format(date){
            return moment(date , 'YYYY-MM-DD HH:mm:ss').format("DD MMM YYYY");
        }
    }
}